






































































import { Component, Vue } from "vue-property-decorator";
import {
  CellGroup,
  Cell,
  Field,
  Uploader,
  Button as VantButton,
  Popup,
  Picker,
  Toast,
} from "vant";
import FeedBackApi, { FeedBackType } from "@/api/feed-back.api";
import { ToolsApi } from "@/api/tools.api";
import ImageUploader from "@/components/ImageUploader.vue";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: {
    ImageUploader,
    CellGroup,
    Cell,
    Field,
    Uploader,
    VantButton,
    Popup,
    Picker,
  },
})
export default class ContactUs extends Vue {
  message = "";
  feedBackText = "请选择一个主题";
  feedBackTypeId = -1;
  fileList: { file: File }[] = [];
  width = 0;
  types: FeedBackType[] = [];
  columns: string[] = [];
  showPicker = false;
  pickerLoading = false;

  globalState = getModule(GlobalStateStore);

  get isLogin(): boolean {
    return !!this.globalState.userToken;
  }

  onOversize(): void {
    Toast("文件大小不能超过 1MB");
  }

  get fileStr(): string {
    const { fileList } = this;
    return `${fileList.length}/9张`;
  }

  mounted(): void {
    const { clientWidth = 0, offsetWidth = 0 } =
      document.querySelector(".van-cell__label")?.parentElement || {};
    this.width = (clientWidth || offsetWidth) / 3 - 8;
  }

  handleSelectFeedBack(): void {
    this.showPicker = true;
    this.loadTypeList();
  }

  async loadTypeList(): Promise<void> {
    if (this.types.length) return;
    this.pickerLoading = true;
    this.types = await FeedBackApi.getTypeList();
    this.columns = this.types.map((row) => row.name);
    this.pickerLoading = false;
  }

  onConfirm(value: string, i: number): void {
    this.showPicker = false;
    const { id, name } = this.types[i];
    this.feedBackTypeId = id;
    this.feedBackText = name;
  }

  async handleSubmit(): Promise<void> {
    const { feedBackTypeId: feedback_type, message: content, fileList } = this;
    if (feedback_type === -1) {
      Toast("请选择反馈类型", { icon: "none" });
      return;
    }
    if (!content.trim()) {
      Toast("请输入反馈内容", { icon: "none" });
      return;
    }
    let img: string[] = [];
    const toast = Toast.loading({ mask: true, duration: 3000 });
    if (fileList.length) {
      img = await ToolsApi.bulkUpload(
        fileList.map((row) => row.file),
        "feedback"
      ).then((ret) => ret.map((str) => `https://cdn.vgn.cn${str}`));
    }
    const result = await FeedBackApi.submitInfo({
      feedback_type: this.feedBackTypeId,
      content: this.message,
      img,
    })
      .then(() => true)
      .catch(() => false);
    toast.close();
    if (!result) {
      Toast("反馈失败了");
      return;
    }
    this.fileList = [];
    this.message = "";
    this.feedBackTypeId = -1;
    this.feedBackText = "请选择一个主题";
    Toast.success({ message: "成功反馈" });
  }
}
