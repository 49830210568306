import axios from "axios";

export default class FeedBackApi {
  static async getTypeList(): Promise<FeedBackType[]> {
    return axios.get(`/apiv2/feedback/type/list`);
  }

  static async submitInfo(params: SubmitInfo) {
    return axios.post(`/apiv2/feedback/info`, params);
  }
}

export interface SubmitInfo {
  feedback_type: number;
  content: string;
  img: string[];
}

export interface FeedBackType {
  id: number;
  name: string;
}
