











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ImageUploader extends Vue {
  @Prop({ default: 120 }) private readonly width!: number;

  get rootStyle(): Record<string, string> {
    const { width } = this;
    return {
      "--width": `${width}px`,
    };
  }

  handleSelectImage(): void {
    // wx.chooseImage({
    //   count: 9, // 默认9
    //   sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
    //   sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
    //   success: (res) => {
    //     console.log(res);
    //     const localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
    //   },
    // });
  }
}
