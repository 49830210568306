import axios from "axios";

export class ToolsApi {
  static async bulkUpload(files: File[], business: string): Promise<string[]> {
    const formData = new FormData();
    files.forEach((file) => formData.append("file[]", file));
    formData.append("business", business);
    return axios.post("/apiv2/tool/bulkUpload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
